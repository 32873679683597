body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --red: #ec412c;
  --primary: #1F2122;
  --secondary: #27292A;
}

.primary-bg{
  background-color: var(--primary);
}

.secondary-bg{
  background-color: var(--secondary);
}

.red-bg{
  background-color: var(--red);
}


.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* adjust the maximum width as needed */
}

