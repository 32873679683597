.login-container {
    text-align: center !important;
    /* margin-top: 90px; */
}



.login-card {
    background-color: #27292A !important;
    border-radius: 20px !important;
    /* padding: 30px; */
    color: white !important;
    /* width: 50%; */
}


.login-content {
    background-color: #27292A;
    border-radius: 20px;
    color: white !important;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27) !important;
    border-radius: 2px;
    width: 500px !important;
    display: inline-block !important;
    margin-top: 30px !important;
    vertical-align: middle !important;
    position: relative !important;
    padding: 35px !important;
}

.social-btn {
    margin-bottom: 15px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: black !important;
    background-color: whitesmoke !important;
}

.social-btn img {
    height: 32px !important;
    float: left !important;
    margin-top: 10px !important;
}

.social-btn.google {
    margin-top: 7px !important;
}

.social-btn.facebook img {
    height: 24px !important;
    margin-left: 3px !important;
}

.social-btn.github img {
    height: 24px !important;
    margin-left: 3px !important;
}

.signup-link {
    color: whitesmoke !important;
    font-size: 14px !important;
    margin-top: 100px;
}

.login-title {
    font-size: 1.5em !important;
    font-weight: 500 !important;
    margin-top: 0 !important;
    margin-bottom: 30px !important;
    color: whitesmoke !important
}